<template>
  <div>
    <div v-if="loading || scheduleDialogOpen" class="text-center pt-12">
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <span class="title grey--text text--darken-1">{{
        $_t('Select region')
      }}</span>
      <v-btn
        v-if="selectedScanRegionId"
        color="primary"
        class="mt-sm-n2 ml-sm-4 text-none"
        small
        @click="clearSelection"
        >{{ $_t('Select different region') }}</v-btn
      >
      <div
        v-if="
          scanRegions[selectedScanId] &&
            scanRegions[selectedScanId][selectedBodyPartId]
        "
        class="py-6"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-row
              v-for="r in scanRegions[selectedScanId][selectedBodyPartId]"
              v-bind:key="r.region_id"
            >
              <v-col
                v-if="
                  !selectedScanRegionId || selectedScanRegionId === r.region_id
                "
                cols="12"
              >
                <v-btn
                  x-large
                  @click="onScanRegionClicked(r.region_id)"
                  :outlined="r.region_id !== selectedScanRegionId"
                  color="primary"
                  class="full-width"
                >
                  {{ r.region_name }}
                  <v-spacer></v-spacer>
                  <v-icon v-if="r.region_id !== selectedScanRegionId"
                    >mdi-chevron-right</v-icon
                  >
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <div
              v-if="
                clinics[selectedBodyPartId] &&
                  clinics[selectedBodyPartId][selectedScanRegionId]
              "
            >
              <v-row
                v-for="c in clinics[selectedBodyPartId][selectedScanRegionId]"
                v-bind:key="c.clinic_id"
                class="mb-5"
              >
                <v-col cols="12">
                  <v-card>
                    <v-card-title>{{ c.clinic_name }}</v-card-title>
                    <v-card-text>
                      <div class="divider-line"></div>
                      <div class="pa-2 pl-5">
                        <span>{{ $_t('First free slot') }}:</span>
                        <span class="font-weight-bold text-lowercase">
                          {{
                            moment(c.availability_date).format(
                              config.dateFormat
                            )
                          }}&nbsp;
                          {{ c.availability_start.substring(0, 5) }}&nbsp; ({{
                            $_t('in')
                          }}
                          {{ daysLeft(new Date(c.availability_date)) }}
                          {{
                            daysLeft(new Date(c.availability_date)) === 1
                              ? $_t('day')
                              : $_t('days')
                          }})
                        </span>
                      </div>
                      <div class="divider-line"></div>
                      <div class="pa-2 pl-5">
                        <span>{{ $_t('Address') }}:&nbsp;</span>
                        <span class="font-weight-bold">{{
                          c.clinic_address
                        }}</span>
                      </div>
                      <div v-if="c.price_min" class="divider-line"></div>
                      <div class="pa-2 pl-5">
                        <span v-if="c.price_min">{{ $_t('Price from') }}:&nbsp;</span>
                        <span v-if="c.price_min" class="font-weight-bold"
                          >{{ Number(c.price_min).toFixed(2) }} </span
                        ><span v-if="c.price_min" class="font-weight-bold"
                          >{{ $_t('currency symbol') }} *</span
                        >
                        <span class="pl-2" v-if="c.pricing_link">
                          <a :href="c.pricing_link" target="_blank">{{ $_t('Detailed pricing info') }}</a>
                        </span>
                      </div>
                      <div class="divider-line"></div>
                    </v-card-text>
                    <v-card-actions class="pt-0" :style="($vuetify.breakpoint.smAndDown) ? 'display: grid' : ''">
                      <v-btn
                        v-if="c.clinic_map_url"
                        :class="[($vuetify.breakpoint.smAndDown) ? 'full-width' : '', 'text-none']"
                        color="primary"
                        text
                        small
                        @click="openMapDialog(c.clinic_map_url)"
                      >
                      {{ $_t('Show on map') }}
                        <v-icon>mdi-map-marker-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        @click="showMap(c)"
                        :class="($vuetify.breakpoint.smAndDown) ? 'full-width' : '' + ' text-none'"
                        color="primary"
                        text
                        small
                      >
                        {{ $_t('Show on map') }}
                        <v-icon>mdi-map-marker-outline</v-icon>
                      </v-btn>
                      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                      <v-btn
                        @click="onClinicClicked(c)"
                        :class="(($vuetify.breakpoint.smOnly) ? 'full-width' : '') + ' text-none'"
                        color="primary"
                        text
                        large
                      >
                        <span
                          >{{ $_t('Schedule a visit') }} /
                          <br class="d-block" />{{
                            $_t('Check available slots')
                          }}</span
                        >
                        <v-icon class="ml-1">mdi-calendar-month</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <p v-if="showPriceInfo" class="subtitle-2">
                * {{ $_t('Price can change based on ...') }}
                <br />
                {{ $_t('Information not the offer with respect to ...') }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <ClinicSchedule v-if="selectedClinicId"></ClinicSchedule>
    <Maps 
      v-if="showMapDialog"
      :dialog="showMapDialog"
      @close="showMapDialog = false"
      :iframeSrc="mapsUrl"
    />
  </div>
</template>

<script>
import config from '@/config';
import { ref, onMounted, computed } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import rest from '@/plugins/rest';
import Vue from 'vue';
import ClinicSchedule from '@/components/Clinic/ClinicSchedule';
import moment from 'moment';
import Maps from '../Shared/Maps.vue';

export default {
  name: 'Clinic',
  components: { ClinicSchedule, Maps },
  setup() {
    let loading = ref(true);
    let showMapDialog = ref(false);
    let mapsUrl = ref('');
    let scanRegions = computed(() => bookingStore.getState().scanRegions);
    let clinics = computed(() => bookingStore.getState().clinics);
    let selectedScanId = computed(() => bookingStore.getState().scanId);
    let selectedBodyPartId = computed(() => bookingStore.getState().bodyPartId);
    let selectedInsurer = computed(() => bookingStore.getState().insurer);
    let selectedScanRegionId = computed(
      () => bookingStore.getState().scanRegionId
    );
    let selectedClinicId = computed(() => bookingStore.getState().clinicId);
    let showPriceInfo = computed(() => {
      if (!clinics.value) {
        return false;
      }
      const clinicsArr = Object.values(
        clinics.value[selectedBodyPartId.value][selectedScanRegionId.value]
      );
      const clinicsWithPrice = clinicsArr.filter((c) => c.price_min);
      return clinicsWithPrice.length > 0;
    });
    let scheduleDialogOpen = computed(
        () => bookingStore.getState().scheduleDialogOpen
    );

    const init = async () => {
      bookingStore.clearSelectionsOnClinic();
      bookingStore.setShowStepper(true);
      bookingStore.setStepName('clinic');
      if (
        !scanRegions[selectedScanId.value] ||
        !scanRegions[selectedScanId.value][selectedBodyPartId.value]
      ) {
        loading.value = true;
        const scanRegions = await getScanRegions(
          selectedScanId.value,
          selectedBodyPartId.value
        );
        if (!scanRegions) {
          Vue._notify.error('Failed to fetch scan regions');
          return;
        }
        const existingScanRegions = bookingStore.getState().scanRegions;
        existingScanRegions[selectedScanId.value] = [];
        existingScanRegions[selectedScanId.value][
          selectedBodyPartId.value
        ] = scanRegions;
        bookingStore.setScanRegions(existingScanRegions);
        loading.value = false;
      }
    };

    const openMapDialog = (url) => {
      showMapDialog.value = true
      mapsUrl.value = url
    }

    const onScanRegionClicked = async (scanRegionId) => {
      if (scanRegionId === selectedScanRegionId.value) {
        return;
      }
      bookingStore.setScanRegionId(scanRegionId);
      if (
        !clinics[selectedBodyPartId.value] ||
        !clinics[selectedBodyPartId.value][scanRegionId]
      ) {
        loading.value = true;
        const clinics = await getClinics(
          selectedBodyPartId.value,
          selectedInsurer.value.insurer_id,
          scanRegionId
        );

        if (!clinics) {
          Vue._notify.error('Failed to fetch clinics');
          return;
        }
        const existingClinics = bookingStore.getState().clinics;
        existingClinics[selectedBodyPartId.value] = [];
        existingClinics[selectedBodyPartId.value][scanRegionId] = clinics;
        bookingStore.setClinics(existingClinics);
        loading.value = false;
      }
    };

    const onClinicClicked = async (clinic) => {
      bookingStore.setScheduleDialogOpen(true);
      bookingStore.setClinicId(clinic.clinic_id);
      bookingStore.setClinic(clinic);
    };

    const clearSelection = () => {
      bookingStore.setScanRegionId(null);
      bookingStore.setClinicId(null);
      bookingStore.setClinic(null);
    };

    const dayNumberToText = (day) => {
      switch (day) {
        case 0:
          return 'Monday';
        case 1:
          return 'Tuesday';
        case 2:
          return 'Wednesday';
        case 3:
          return 'Thursday';
        case 4:
          return 'Friday';
        case 5:
          return 'Saturday';
        case 6:
          return 'Sunday';
      }
    };

    const daysLeft = (date2) => {
      const date1 = new Date();
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      const utc1 = Date.UTC(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
      );
      const utc2 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
      );

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };

    const getScanRegions = async (scanId, bodyPartId) => {
      let scanRegions = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const scanRegionsResponse = await rest.get(
          bookingStore.getState().apiUrls.getScanRegionsUrl,
          {
            scanId: scanId,
            bodyPartId: bodyPartId
          }
        );
        if (scanRegionsResponse.status === 'S') {
          scanRegions = scanRegionsResponse.response.scanRegions;
        }
      } catch {}
      return scanRegions;
    };

    const getClinics = async (bodyPartId, insurerId, scanRegionId) => {
      let clinics = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const clinicsResponse = await rest.get(
          bookingStore.getState().apiUrls.getClinicsUrl,
          {
            bodyPartId: bodyPartId,
            regionId: scanRegionId,
            insurerId: insurerId
          }
        );
        if (clinicsResponse.status === 'S') {
          clinics = clinicsResponse.response.clinics;
        }
      } catch {}
      return clinics;
    };

    const showMap = (clinic) => {
      window.open(
        'https://www.google.com/maps/search/?api=1&query=' +
          clinic.clinic_lat +
          ',' +
          clinic.clinic_lng,
        '_blank'
      );
    };

    onMounted(init);

    return {
      config,
      loading,
      scanRegions,
      clinics,
      onScanRegionClicked,
      onClinicClicked,
      selectedScanId,
      selectedBodyPartId,
      selectedScanRegionId,
      selectedClinicId,
      showPriceInfo,
      dayNumberToText,
      daysLeft,
      showMap,
      clearSelection,
      moment,
      scheduleDialogOpen,
      openMapDialog,
      showMapDialog,
      mapsUrl
    };
  }
};
</script>

<style scoped></style>
