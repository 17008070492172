import Vue from 'vue';

const notifyContent = {
  snackbar: false,
  snackbarText: '',
  success (msg, title, group) {
    Vue.notify({
      group: group || 'booking',
      type: 'success',
      title: Vue.prototype.$_t(title) || Vue.prototype.$_t('Success'),
      text: Vue.prototype.$_t(msg)
    })
  },
  info (msg, title, group) {
    Vue.notify({
      group: group || 'booking',
      type: 'info',
      title: Vue.prototype.$_t(title) || Vue.prototype.$_t('Info'),
      text: Vue.prototype.$_t(msg)
    })
  },
  error (msg, title, group) {
    Vue.notify({
      group: group || 'booking',
      type: 'error',
      title: Vue.prototype.$_t(title) || Vue.prototype.$_t('Error'),
      text: Vue.prototype.$_t(msg)
    })
  },
  log (msg) {
    console.warn(Vue.prototype.$_t(msg))
  }
}

const notify = {
  install (Vue) {
    Vue.prototype.$_notify = notifyContent
    Vue._notify = notifyContent
  }
}

export default notify;
