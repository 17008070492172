<template>
  <div>
    <div v-if="loading" class="text-center pt-12">
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <span class="title grey--text text--darken-1">{{
        $_t('Select procedure')
      }}</span>
      <v-btn
        v-if="selectedScanId"
        color="primary"
        class="mt-sm-n2 ml-sm-4 text-none"
        small
        @click="clearSelection"
        >{{ $_t('Select different procedure') }}</v-btn
      >
      <div v-if="scans.length" class="py-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-row
              v-for="s in scansFilteredByReferralStep"
              v-bind:key="s.scan_id"
            >
              <v-col
                v-if="!selectedScanId || selectedScanId === s.scan_id"
                cols="12"
              >
                <v-btn
                  x-large
                  @click="onScanClicked(s.scan_id, s.require_full_web_booking_process)"
                  :outlined="s.scan_id !== selectedScanId"
                  color="primary"
                  class="full-width text-left procedure-btn"
                >
                  {{ s.scan_name_web }}
                  <v-spacer></v-spacer>
                  <v-icon v-if="s.scan_id !== selectedScanId"
                    >mdi-chevron-right</v-icon
                  >
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" v-if="selectedScanId">
            <v-row>
              <v-col
                cols="12"
                v-for="b in bodyParts[selectedScanId]"
                v-bind:key="b.body_part_id"
              >
                <v-btn
                  x-large
                  @click="onBodyPartClicked(b.body_part_id)"
                  :outlined="b.body_part_id !== selectedBodyPartId"
                  color="primary"
                  class="full-width text-left procedure-btn"
                >
                        {{ b.body_part_name_web }}
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { bookingStore } from '@/store/booking-store'
import { ref, computed, onMounted } from '@vue/composition-api'
import rest from '@/plugins/rest'
import Vue from 'vue'
import ROUTE_NAMES from '@/store/consts/route-names'
import REFERRAL_STEPS from '@/store/consts/referral-steps'
import NO_REFERRAL_ALLOWED_SCANS from '@/store/consts/no-referral-allowed-scans'

export default {
  name: 'Procedure',
  props: ['proceedToInsurers'],
  setup(props, context) {
    let loading = ref(true)
    let scans = computed(() => bookingStore.getState().scans)
    let bodyParts = computed(() => bookingStore.getState().bodyParts)
    let selectedScanId = computed(() => bookingStore.getState().scanId)
    let selectedBodyPartId = computed(() => bookingStore.getState().bodyPartId)
    let scansFilteredByReferralStep = computed(() => {
      const referralStep = bookingStore.getState().referralStep
      if (referralStep === REFERRAL_STEPS.iDontHaveTheReferral) {
        return scans.value.filter((s) =>
          NO_REFERRAL_ALLOWED_SCANS.includes(s.scan_id)
        )
      } else {
        return scans.value
      }
    })

    const init = async () => {
      bookingStore.clearSelectionsOnProcedure()
      bookingStore.setShowStepper(true)
      bookingStore.setStepName('procedure')

      if (!scans.length) {
        loading.value = true
        const scans = await getScans()
        if (!scans) {
          Vue._notify.error('Failed to fetch scans')
          return
        }
        bookingStore.setScans(scans)
        loading.value = false
      }
    }

    const onScanClicked = async (scanId, requireFullWebBookingProcess) => {
      if (requireFullWebBookingProcess === '0') {
        context.root.$router.push(ROUTE_NAMES.callback.path)
        return
      }
      if (scanId !== selectedScanId.value) {
        loading.value = true
      }
      const appointmentProcedureId = await setScan(scanId)
      if (!appointmentProcedureId) {
        Vue._notify.error('Could not assign the selected scan')
        return
      }
      bookingStore.setScanId(scanId)
      bookingStore.setAppointmentProcedureId(
        appointmentProcedureId
      )
      if (!bodyParts[scanId]) {
        const bodyParts = await getBodyParts(scanId)
        if (!bodyParts) {
          Vue._notify.error('Failed to fetch body parts')
          return
        }
        const existingBodyParts = bookingStore.getState().bodyParts
        existingBodyParts[scanId] = bodyParts
        bookingStore.setBodyParts(existingBodyParts)
        loading.value = false
      }
    }

    const onBodyPartClicked = async (bodyPartId) => {
      const appointmentProcedureId = await setScan(selectedScanId.value, bodyPartId)
      if (!appointmentProcedureId) {
        Vue._notify.error('Could not assign procedure')
        return
      }

      bookingStore.setBodyPartId(bodyPartId)
      bookingStore.setAppointmentProcedureId(
        appointmentProcedureId
      )
      goToInsurers()
    }

    const getScans = async () => {
      let scans = null
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const getScansesponse = await rest.get(
          bookingStore.getState().apiUrls.getScansUrl
        )
        if (getScansesponse.status === 'S') {
          scans = getScansesponse.response.scans
        }
      } catch {}
      return scans
    }

    const setScan = async (scanId, bodyPartId = null) => {
      let appointmentProcedureId = null
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const setScanResponse = await rest.get(
          bookingStore.getState().apiUrls.setScanIdUrl,
          { scanId, bodyPartId }
        )
        if (setScanResponse.status === 'S') {
          appointmentProcedureId = setScanResponse.response.appointmentProcedureId
        }
      } catch {}
      return appointmentProcedureId
    }

    const getBodyParts = async (scanId) => {
      let bodyParts = null
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const getBodyPartsResponse = await rest.get(
          bookingStore.getState().apiUrls.getBodyPartsUrl,
          { scanId: scanId }
        )
        if (getBodyPartsResponse.status === 'S') {
          bodyParts = getBodyPartsResponse.response.bodyParts
        }
      } catch {}
      return bodyParts
    }

    const goToInsurers = () => {
      if (props.proceedToInsurers) {
        context.root.$router.push(ROUTE_NAMES.insurers.path)
      }
    }

    const clearSelection = () => {
      bookingStore.setScanId(null)
      bookingStore.setBodyPartId(null)
    }

    onMounted(init)

    return {
      loading,
      config,
      scans,
      scansFilteredByReferralStep,
      onScanClicked,
      bodyParts,
      onBodyPartClicked,
      selectedScanId,
      selectedBodyPartId,
      clearSelection
    }
  }
}
</script>

<style>
.procedure-btn > .v-btn__content {
  white-space: normal;
  flex: auto;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.procedure-btn {
  min-height: 52px;
  height: 100% !important;
}
</style>
