<template>
  <div>
    <div class="background-gradient">
      <div class="pt-12 pb-16 text-center" style="color: #FFFFFF">
        <v-row>
          <v-col cols="12" md="4" offset-md="4">
            <v-row>
              <v-col cols="12">
                <v-icon color="white" size="75">mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="12" class="title">
                {{$_t('Your appointment is booked')}}
              </v-col>
              <v-col cols="12" class="subtitle-2">
                {{$_t('We sent the appointment details to you via text message and email')}}
              </v-col>
              <v-col cols="10" offset="1" class="text-left mt-4">
                <v-row style="background-color: #1552c6; border-radius: 0.5em">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('Clinic')}}</span><br/>
                    <span class="subtitle-1">{{ (clinic) ? clinic.clinic_name + ', ' + clinic.clinic_address : $_t('N/A')}}</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('Procedure')}}</span><br/>
                    <span class="subtitle-1">{{ (procedureName) ? procedureName : $_t('N/A') }}</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('Visit date')}}</span><br/>
                    <span class="subtitle-1">{{ (slot) ? moment(slot.date).format(config.dateFormat) + ' ' + slot.time.slice(0, 5) : $_t('N/A') }}</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('To pay in clinic')}}</span><br/>

                    <span class="caption" v-if="clinic && (clinic.price_min || clinic.price_max)">
                      {{$_t('Price from') }}: {{Number(clinic.price_min).toFixed(2)}}
                      {{ $_t('currency symbol') }}
                      <br>
                      {{$_t('Your final price will be provided once the exact required procedure is confirmed during the visit')}}
                    </span>
                    <span class="subtitle-1" v-else>{{  $_t('N/A') }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <v-row>
        <v-col cols="12" md="4" offset-md="4" class="pa-0">
          <v-card class="ma-0">
            <v-card-text class="pa-8">
              <span class="title-2">
                {{$_t('Confirmation text 1')}}
              </span><br/><br/>
              <span v-if="alternativeConfirmation">
                {{ $_t('Confirmation text 3') }}
              </span>
              <ul>
                <li class="subtitle-2">{{$_t('Confirmation text list option 1')}}</li>
                <li class="subtitle-2" v-html="$_t('Confirmation text list option 2')"></li>
                <li v-if="alternativeConfirmation" class="subtitle-2" v-html="$_t('Confirmation text list option 3')"></li>
              </ul><br/>
              <span class="title-2">
                {{$_t('Confirmation text 2')}}
              </span><br/><br/>
              <span v-if="alternativeConfirmation">
                {{ $_t('Confirmation footer text') }}
              </span><br/><br/>
              <v-btn text class="text-none" color="primary" v-if="selectedScan && learnMore(selectedScan.scan_code)" :href="learnMore(selectedScan.scan_code)" target="_blank">{{$_t('Learn more')}}</v-btn><br/>
              <div class="divider-line mt-1"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { bookingStore } from '@/store/booking-store'
import {ref, onMounted, computed, defineComponent, onBeforeUnmount} from '@vue/composition-api'
import config from '@/config'
import moment from 'moment/moment'

export default defineComponent({
  name: 'Confirmation',
  components: {},
  setup() {
    const loading = ref(true)
    const clinic = computed(() => bookingStore.getState().clinic)
    const refProcedure = computed(() => bookingStore.getState().referralProcedure)
    const procedureName = ref('')
    const bodyPartId = computed(() => bookingStore.getState().bodyPartId)
    const bodyParts = computed(() => bookingStore.getState().bodyParts)
    const scanId = computed(() => bookingStore.getState().scanId)
    const scans = computed(() => bookingStore.getState().scans)
    const slot = computed(() => bookingStore.getState().slot)
    const selectedScan = ref(null)
    const alternativeConfirmation = ref(config.alternativeConfirmation)

    const init = () => {
      bookingStore.setShowStepper(false)
      loading.value = false
      if (!refProcedure.value) {
        selectedScan.value = scans.value.find(obj => obj.scan_id === scanId.value)

        let bodyPart = bodyParts.value[scanId.value].find((obj) => {
          return obj.body_part_id === bodyPartId.value
        })

        procedureName.value = selectedScan.value.scan_name_web + ' - ' + bodyPart.body_part_name_web
      } else {
        procedureName.value = refProcedure.value.procedure_name
        selectedScan.value = {
          scan_code: refProcedure.value.scan_code
        }
      }
    }

    onMounted(init)

    const learnMore = (scanCode) => {
      if (scanCode === 'MR') {
        scanCode = 'MRI'
      }
      if (scanCode === 'TK') {
        scanCode = 'CT'
      }

      return config.scanLearnMoreLinks[scanCode]
    }

    const clearSelectionsAndRedirect = () => {
      bookingStore.clearSelectionsOnClinic()
      bookingStore.clearSelectionsOnProcedure()
      bookingStore.clearSelectionsOnInsurer()
      window.location = config.homepageLink
    }

    onBeforeUnmount( () => clearSelectionsAndRedirect())

    return {
      loading,
      clinic,
      procedureName,
      config,
      selectedScan,
      learnMore,
      moment,
      slot,
      alternativeConfirmation
    }
  },
})
</script>

