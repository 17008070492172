<template>
  <div>
    <v-row justify="center">
      <v-col v-if="!callbackFormSaved" rows="12" lg="6">
        <p class="mb-4 title grey--text text--darken-1">
          {{ $_t('Please enter callback details 1') }}
        </p>
        <p class="mb-4 title grey--text text--darken-1">
          {{ $_t('Please enter callback details 2') }}
        </p>
        <p class="mb-8 title grey--text text--darken-1">
          {{ $_t('Please enter callback details 3') }}
        </p>
        <v-form ref="callbackFormEl" v-model="callbackFormValid">
          <v-text-field
            v-model="callbackForm.firstName"
            :label="$_t('First name')"
            :rules="[validationRules.firstName]"
            :disabled="callbackFormSaving"
            type="text"
            color="primary"
            class="mb-2"
            required
            outlined
          ></v-text-field>
          <v-text-field
            v-model="callbackForm.lastName"
            :label="$_t('Last name')"
            :rules="[validationRules.lastName]"
            :disabled="callbackFormSaving"
            type="text"
            color="primary"
            class="mb-2"
            required
            outlined
          ></v-text-field>
          <v-text-field
            v-model="callbackForm.mobileNumber"
            :label="$_t('Mobile number')"
            :rules="[validationRules.mobilePhone]"
            :disabled="callbackFormSaving"
            type="text"
            color="primary"
            class="mb-2"
            required
            outlined
            validate-on-blur
          ></v-text-field>
          <v-text-field
            v-model="callbackForm.emailAddress"
            :label="callbackForm.agreementEmail ? $_t('Email address') : $_t('Email address (optional)')"
            :rules="[validationRules.emailAddress]"
            :disabled="callbackFormSaving"
            type="text"
            color="primary"
            class="mb-2"
            required
            outlined
            validate-on-blur
          ></v-text-field>
          <span class="d-block mb-2 grey--text text--darken-1">{{
            $_t('Select preferred callback time')
          }}</span>
          <div>
            <v-btn-toggle v-model="callbackForm.contactDay" class="mb-4">
              <v-btn
                v-for="day in callbackDays"
                :key="day.text"
                :value="day"
                @click="onCallbackDayClicked"
                color="primary"
                class="mr-2"
              >
                {{ day.text }}
              </v-btn>
            </v-btn-toggle>
          </div>
          <div>
            <v-btn-toggle v-model="callbackForm.contactTime" class="mb-8">
              <v-btn
                v-for="time in callbackTimes"
                :key="time.text"
                :value="time"
                color="primary"
                class="mr-2"
              >
                {{ time.text }}
              </v-btn>
            </v-btn-toggle>
          </div>
          <p class="mb-4 grey--text text--darken-1">
            Administratorem Twoich danych osobowych jest Affidea Sp. z o.o.,
            Plac Europejski 2, 00-844 Warszawa. Twoje dane osobowe będą
            przetwarzane w celu kontaktu telefonicznego, na który wyraziłeś
            zgodę. Szczegółowe informacje na temat przetwarzania danych
            osobowych zawarte są w
            <a
              href="https://affidea.pl/informacja-o-ochronie-danych-osobowych-dla-pacjentow/"
              target="_blank"
              >Informacji o ochronie danych osobowych</a
            >. Udzieloną zgodę możesz w każdej chwili cofnąć wysyłając
            informacje na adres
            <a href="mailto:iod@affidea.com">iod@affidea.com</a>.
          </p>
          <v-checkbox
            v-model="callbackForm.contactAgreement"
            :rules="[validationRules.contactAgreement]"
            :disabled="callbackFormSaving"
            :hide-details="true"
            class="mt-0 mb-0"
            required
          >
            <template v-slot:label>
              <div>
                *
                {{ $_t('I agree to be contacted by Affidea') }}.
                <v-menu
                  max-width="460"
                  nudge-top="20"
                  close-delay="100"
                  open-on-hover
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a v-on="on" v-bind="attrs">
                      {{ $_t('What it means?') }}
                    </a>
                  </template>
                  <v-alert color="primary accent-4" class="ma-0" colored-border>
                    Podając w formularzu swoje dane kontaktowe, zaznaczając
                    niniejsze okno oraz naciskając przycisk „WYŚLIJ”,
                    przekazujesz nam swoje dane i wyrażasz zgodę na ich
                    przetwarzanie w celu jednorazowego kontaktu telefonicznego
                    ze strony Affidea w celu zadania nam pytań dotyczących
                    naszej działalności, świadczonych przez nas usług medycznych
                    lub korzystania z Serwisu i jego funkcjonalności.
                  </v-alert>
                </v-menu>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="callbackForm.termsAndConditions"
            :rules="[validationRules.termsAndConditions]"
            :disabled="callbackFormSaving"
            :hide-details="true"
            class="mt-0 mb-4"
            required
          >
            <template v-slot:label>
              <div>
                *
                {{ $_t('I accept') }}
                <a
                  @click.stop
                  href="https://affidea.pl/regulamin-serwisu/"
                  target="_blank"
                  >{{ $_t('terms and conditions') }}</a
                >.
              </div>
            </template>
          </v-checkbox>
          <p class="mb-8 grey--text text--darken-2">
            {{ $_t('I agree for the marketing communication by Affidea') }}:
            <v-menu
              max-width="460"
              nudge-top="20"
              close-delay="100"
              open-on-hover
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <a v-on="on" v-bind="attrs">
                  {{ $_t('What it means?') }}
                </a>
              </template>
              <v-alert color="primary accent-4" class="ma-0" colored-border>
                Zaznaczając niniejsze pole i naciskając przycisk „Wyślij prośbę”
                wyrażasz dobrowolną zgodę na przetwarzanie Twoich danych
                osobowych przez Affidea* w celu otrzymywania informacji o
                najnowszej ofercie Affidea* w zakresie usług medycznych za
                pośrednictwem środków komunikacji, na które wyrazisz zgodę
                poprzez zaznaczenie określonych pól (tj. poczta elektroniczna /
                wiadomości SMS/MMS / kontakt telefoniczny).
                <br />(*) Affidea Sp. z o.o., Plac Europejski 2, 00-844 Warszawa
              </v-alert>
            </v-menu>
            <v-checkbox
              v-model="callbackForm.agreementEmail"
              :disabled="callbackFormSaving"
              :hide-details="true"
              class="ma-0 ml-4"
            >
              <template v-slot:label>
                <div>
                  {{ $_t('agreement email') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="callbackForm.agreementSMS_MMS"
              :disabled="callbackFormSaving"
              :hide-details="true"
              class="ma-0 ml-4"
            >
              <template v-slot:label>
                <div>
                  {{ $_t('agreement sms/mms') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="callbackForm.agreementPhone"
              :disabled="callbackFormSaving"
              :hide-details="true"
              class="ma-0 ml-4"
            >
              <template v-slot:label>
                <div>
                  {{ $_t('agreement phone') }}
                </div>
              </template>
            </v-checkbox>
          </p>
          <v-btn
            @click="onSaveCallbackClicked"
            :loading="callbackFormSaving"
            color="primary"
            large
            class="text-none full-width"
            type="submit"
            >{{ $_t('Save callback') }}</v-btn
          >
        </v-form>
      </v-col>
      <v-col v-if="callbackFormSaved" rows="12" lg="6">
        <v-alert dense text type="success" class="mt-4 pa-12">{{
          $_t('Request callback has been successfully sent.')
        }}</v-alert>
      </v-col>
    </v-row>
    <v-alert
        v-model="showAlert"
        dismissible
        outlined
        elevation="24"
        prominent
        text
        type="error"
    >
      {{$_t('Exceeded Callback Time, please select other time')}}
    </v-alert>
  </div>
</template>

<style scoped>
.theme--light.v-btn.v-btn--has-bg.v-btn--active {
  background-color: #0f5ba7 !important;
  color: white !important;
}
</style>

<script>
import Vue from 'vue';
import rest from '@/plugins/rest';
import { ref, reactive, computed } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import CALLBACK_TIMES from '@/store/consts/callback-times';
import moment from 'moment/moment';
import config from '@/config';

const TODAY_MAX_HOUR_THRESHOLD = 18;
const TODAY_MAX_HOUR = 20;

export default {
  name: 'Callback',
  setup(_, context) {
    //page details
    bookingStore.setStep({
      title: Vue.prototype.$_t('Contact us'),
      subTitle: Vue.prototype.$_t(
        'In case of any questions or doubts reach out to us'
      ),
      showStepper: false
    });
    const excludeToday = ref(config.excludeCurrentDayFromCallback)
    const callbackFormEl = ref(null);
    const callbackFormValid = ref(false);
    const callbackFormSaved = ref(false);
    const callbackFormSaving = ref(false);
    const showAlert = ref(false);
    const callbackForm = reactive({
      firstName: null,
      lastName: null,
      mobileNumber: null,
      agreementEmail: null,
      agreementSMS_MMS: null,
      agreementPhone: null,
      contactDay: null,
      contactTime: null,
      contactAgreement: null,
      termsAndConditions: null
    });
    const todayMaxHourThresholdReached = computed(
      () => moment().hour() >= TODAY_MAX_HOUR_THRESHOLD
    );
    const todayMaxHourReached = computed(
      () => moment().hour() >= TODAY_MAX_HOUR
    );
    const callbackDays = computed(() => {
      var start = moment().add(1, 'days');
      var end = moment().add(7, 'days');
      var days = [];
      if(!excludeToday){
        if (!todayMaxHourThresholdReached.value && !todayMaxHourReached.value) {
          days.push({
            text: `${moment().format('DD.MM')} (${Vue.prototype.$_t(
                moment().format('ddd')
            )})`,
            isToday: true
          });
        }
      }

      for (start; start.isBefore(end); start.add(1, 'days')) {
        if (start.weekday() !== 0 && start.weekday() !== 6) {
          days.push({
            text: `${start.format('DD.MM')} (${Vue.prototype.$_t(
              start.format('ddd')
            )})`,
            isToday: false
          });
        }
      }
      return days;
    });
    const callbackTimes = computed(() => {
      if (
        callbackForm.contactDay &&
        callbackForm.contactDay.isToday &&
        !todayMaxHourThresholdReached.value &&
        !todayMaxHourReached.value
      ) {
        return CALLBACK_TIMES.filter((ct) => ct.minHour > moment().hour());
      } else {
        return CALLBACK_TIMES;
      }
    });

    const callbackMaxHourExceeded = computed (() => {
      return callbackForm.contactTime.maxHour <= moment().hour();
    })

    callbackForm.contactDay = callbackDays.value[0];
    callbackForm.contactTime = callbackTimes.value[0];

    const validationRules = {
      contactAgreement: (value) =>
        !!value || Vue.prototype.$_t('Please accept to be contacted.'),
      termsAndConditions: (value) =>
        !!value || Vue.prototype.$_t('Please accept terms and conditions.'),
      firstName: (value) =>
        !!value || Vue.prototype.$_t('Please enter correct first name.'),
      lastName: (value) =>
        !!value || Vue.prototype.$_t('Please enter correct last name.'),
      mobilePhone: (value) => {
        const pattern = /(^|\W)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/;
        return (
          pattern.test(value) ||
          Vue.prototype.$_t('Please enter correct mobile number.')
        );
      },
      emailAddress: (value) => {
        const pattern = /^$|.+@.+\..+/;
        return (
          !callbackForm.agreementEmail ||
          (pattern.test(value) && value.length <= 50) ||
          Vue.prototype.$_t('Please enter correct e-mail address.')
        );
      }
    };
    const onCallbackDayClicked = () => {
      Vue.nextTick(function() {
        callbackForm.contactTime = callbackTimes.value[0];
      });
    };
    const onSaveCallbackClicked = (e) => {
      if (callbackForm.contactDay.isToday && callbackMaxHourExceeded.value){
        showAlert.value = true
        return
      }
      callbackFormEl.value.validate();
      Vue.nextTick(function() {
        if (!callbackFormValid.value) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        } else {
          saveCallback();
        }
      });
      e.preventDefault();
    };

    const saveCallback = async () => {
      try {
        const saveCallbackUrl = bookingStore.getState().apiUrls.saveCallbackUrl;
        callbackFormSaving.value = true;

        const response = await rest.post(saveCallbackUrl, {
          patientFirstName: callbackForm.firstName,
          patientLastName: callbackForm.lastName,
          patientMobileNumber: callbackForm.mobileNumber,
          contactDay: callbackForm.contactDay.text,
          contactTime: callbackForm.contactTime ? callbackForm.contactTime.text : callbackTimes.value[0].text,
          agreements: {
            agreementEmail: callbackForm.agreementEmail,
            agreementSMS_MMS: callbackForm.agreementSMS_MMS,
            agreementPhone: callbackForm.agreementPhone
          }
        });

        callbackFormSaving.value = false;

        if (response.status === 'S') {
          callbackFormSaved.value = true;
        } else {
          Vue._notify.error(
            Vue.prototype.$_t(
              'Could not save callback request. Please try again.'
            )
          );
        }
      } catch {
        callbackFormSaving.value = false;
        Vue._notify.error(
          Vue.prototype.$_t(
            'Could not save callback request. Please try again.'
          )
        );
      }
    };

    const goToRoute = (routeName) => {
      context.root.$router.push(routeName).catch(() => {});
    };

    return {
      //data
      callbackDays,
      callbackTimes,
      callbackFormEl,
      callbackFormSaved,
      callbackFormValid,
      callbackFormSaving,
      callbackForm,
      validationRules,
      showAlert,
      //methods
      onCallbackDayClicked,
      onSaveCallbackClicked,
      goToRoute
    };
  }
};
</script>
