import Vue from 'vue'
import VueRouter from 'vue-router'
import Booking from '../views/Booking.vue'
import config from '@/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: config.startPage
  },
  {
    path: '*',
    name: 'Booking',
    component: Booking
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
