<template>
  <div>
    <div>
      <v-stepper alt-labels v-model="stepperModel">
        <v-stepper-header>
          <template v-for="n in maxSteps">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="stepperModel > n"
              :step="n"
              :color="stepperModel > n ? 'green' : 'primary'"
              class="text-center"
            >
              <div class="text-center">
                {{ $_t(stepLabel(n)) }}
              </div>
            </v-stepper-step>
            <v-divider v-if="n !== maxSteps" :key="n"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-chip color="primary" outlined v-if="stepValues[1]" class="mr-1 mt-1 text-left pa-2">
        <span class="v-stepper__step__step primary white--text font-weight-bold">1</span>{{$_t('Procedure')}}:
        <span class="font-weight-bold ml-4">{{ stepValues[1]}}</span>
      </v-chip>
      <br />
      <v-chip color="primary" outlined v-if="stepValues[2]" class="mr-1 mt-1 text-left pa-2">
        <span class="v-stepper__step__step primary white--text font-weight-bold" style="background: white;">2</span>{{$_t('Insurer')}}:
        <span class="font-weight-bold ml-4">{{ stepValues[2] }}</span>
      </v-chip>
      <br />
      <v-chip color="primary" outlined v-if="stepValues[3]" class="mr-1 mt-1 text-left pa-2">
        <span class="v-stepper__step__step primary white--text font-weight-bold" style="background: white;">3</span>{{$_t('Clinic')}}:
        <span class="font-weight-bold ml-4 text-center">{{ stepValues[3] }}</span>
        <span v-if="slot" class="font-weight-bold ml-4 text-center">
          {{ moment(slot.date).format(config.dateFormat) + ' ' + slot.time.slice(0, 5) }}</span>
      </v-chip>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="summary">
      <span v-if="stepValues[1]" class="mr-1 mt-1">
        {{ $_t('Selected procedure') }}:<span class="font-weight-bold ml-1">{{
          stepValues[1]
        }}</span
        ><br />
      </span>
      <span v-if="stepValues[2]" class="mr-1 mt-1">
        {{ $_t('Selected insurer') }}:<span class="font-weight-bold ml-1">{{
          stepValues[2]
        }}</span
        ><br />
      </span>
      <span v-if="stepValues[3]" class="mr-1 mt-1">
        {{ $_t('Selected clinic') }}:<span class="font-weight-bold ml-1">{{
          stepValues[3]
        }}</span>
        <span v-if="slot" class="font-weight-bold"
          >&nbsp;({{
            moment(slot.date).format(config.dateFormat) +
              ' ' +
              slot.time.slice(0, 5)
          }})</span
        >
        <br />
      </span>
    </div>
  </div>
</template>

<style scoped>
.summary {
  font-size: 20px;
}
.v-stepper {
  box-shadow: none !important;
}
.v-chip {
  height: auto !important;
  white-space: pre-wrap !important;
}
.v-chip.v-size--default {
  height: auto !important;
}
</style>

<script>
import config from '@/config';
import { ref, watch, computed, onMounted } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import moment from 'moment/moment';

export default {
  name: 'BookingSteps',
  setup() {
    const stepperModel = ref(1);
    const maxSteps = 4;
    const step = computed(() => bookingStore.getState().step.name);
    const refProcedure = computed(
      () => bookingStore.getState().referralProcedure
    );
    const bodyPartId = computed(() => bookingStore.getState().bodyPartId);
    const bodyParts = computed(() => bookingStore.getState().bodyParts);
    const insurer = computed(() => bookingStore.getState().insurer);
    const clinic = computed(() => bookingStore.getState().clinic);
    const scanId = computed(() => bookingStore.getState().scanId);
    const scans = computed(() => bookingStore.getState().scans);
    const slot = computed(() => bookingStore.getState().slot);
    const stepValues = ref([]);

    const init = () => {
      resolveStepperValue(step.value);
    };

    onMounted(init);

    const stepLabel = (step) => {
      switch (step) {
        case 1:
          return 'Procedure';
        case 2:
          return 'Insurer';
        case 3:
          return 'Clinic';
        case 4:
          return 'Contact details';
      }
    };

    const procedureSelected = () => {
      if (!refProcedure.value && scans.value.length) {
        let scan = scans.value.find((obj) => obj.scan_id === scanId.value);

        let bodyPart = bodyParts.value[scanId.value].find((obj) => {
          return obj.body_part_id === bodyPartId.value;
        });

        let procedureName = scan.scan_name_web;
        if (bodyPart) {
          procedureName += ' - ' + bodyPart.body_part_name_web;
        }

        return procedureName;
      } else if (refProcedure.value) {
        return refProcedure.value.procedure_name;
      }

      return false;
    };

    const insurerSelected = () => {
      if (!insurer.value) {
        return false;
      }

      return insurer.value.insurer_name_web;
    };

    const clinicSelected = () => {
      if (!clinic.value) {
        return false;
      }

      return clinic.value.clinic_name;
    };

    const stepValueSelected = (step) => {
      switch (step) {
        case 1:
          stepValues.value[1] = procedureSelected();
          break;
        case 2:
          stepValues.value[2] = insurerSelected();
          break;
        case 3:
          stepValues.value[3] = clinicSelected();
          break;
      }

      stepValues.value.forEach((val, index) => {
        if (index > step) {
          stepValues.value[index] = null;
        }
      });
    };

    const resolveStepperValue = (value) => {
      let stepId = 1;
      switch (value) {
        case 'start':
        case 'e-referral':
        case 'procedure':
          stepId = 1;
          break;
        case 'insurer':
          stepId = 2;
          break;
        case 'clinic':
          stepId = 3;
          break;
        case 'patient-form':
          stepId = 4;
          break;
      }

      stepperModel.value = stepId;
      if (stepId > 1) {
        stepValueSelected(stepId - 1);
      } else {
        stepValues.value = [];
      }
    };

    watch(step, (current) => {
      resolveStepperValue(current);
    });

    return {
      maxSteps,
      stepperModel,
      stepLabel,
      config,
      step,
      stepValues,
      clinic,
      moment,
      slot
    };
  }
};
</script>
