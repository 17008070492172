<template>
  <div>
    <div v-if="loading" class="text-center pt-4">
      <v-progress-circular
          :size="75"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="pt-4">
      <v-row>
        <v-col cols="12" md="6" offset-md="3" v-if="!patientFormSubmitted">
          <PatientFormComponent></PatientFormComponent>
        </v-col>
        <v-col cols="12" md="6" offset-md="3" v-else>
          <v-card elevation="0">
            <v-card-title class="grey--text text--darken-1">
              <p class="mb-2">
                {{ $_t('Confirm your phone number') }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p class="mb-2 body-1">
                    <span>{{ $_t('We sent a confirmation code via text message to') }}</span>
                    <span class="font-weight-bold">
                  {{ patientFormData.mobile_number_1 }}.
                </span>
                  </p>
                  <p class="mb-6 body-1">
                    {{ $_t('Please enter the code in the field below to confirm your phone number') }}
                  </p>
                  <p class="mb-6 body-1">
                    <v-alert
                        color="blue"
                        icon="mdi-timer"
                        type="success"
                        prominent
                    >
                      <span class="font-weight-bold">{{ twoDigits(confirmationCountdownMinutes) }}&nbsp;:&nbsp;{{ twoDigits(confirmationCountdownSeconds) }}</span>
                      {{ $_t('Complete this step before the timer expires, otherwise this booking will be lost') }}
                    </v-alert>
                  </p>
                  <v-form ref="verificationCodeForm" @submit.prevent="verifySMSCode">
                    <v-text-field
                        v-model="verificationCode"
                        :label="$_t('SMS Code')"
                        :rules="verificationCodeRules"
                        color="primary"
                        class="mb-4"
                        autocomplete="off"
                        hide-details="auto"
                        outlined
                        validate-on-blur
                        @keydown.enter="verifySMSCode"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="12">
                  <v-btn
                      color="primary"
                      class="full-width text-none"
                      @click="verifySMSCode"
                      :disabled="verificationCodeFormSubmitted"
                      x-large
                  >
                    <span v-if="!verificationCodeFormSubmitted">
                      {{ $_t('Confirm') }}
                    </span>
                    <span v-else>
                      <v-progress-circular
                          :size="24"
                          color="primary"
                          indeterminate
                      ></v-progress-circular>
                    </span>
                  </v-btn>
                  <v-alert
                      color="red"
                      type="error"
                      class="mt-8"
                      v-if="verificationCodeError"
                  >{{verificationCodeError}}</v-alert>
                  <v-alert
                      color="red"
                      type="error"
                      class="mt-8"
                      v-if="confirmationError"
                  >
                    {{confirmationError}}<br><br>
                    {{ $_t('Cannot confirm appointment additional info.') }}
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <span>{{ $_t('SMS did not arrive?')}}</span>
                  <v-btn
                      color="primary"
                      class="full-width text-none"
                      @click="resendSMSCode"
                      :disabled="!canResend"
                      x-large
                      outlined
                  >
                    <span v-if="canResend">
                      {{ $_t('Resend SMS') }}
                    </span>
                    <span v-else>
                      {{
                        $_t('Try again in') + ' ' +
                        twoDigits(resendCountdownMinutes) }}&nbsp;:&nbsp;{{ twoDigits(resendCountdownSeconds)
                      }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import {ref, onMounted, defineAsyncComponent, computed, watch } from '@vue/composition-api'
import { bookingStore } from '@/store/booking-store'
import rest from '@/plugins/rest'
import Vue from 'vue'
import ROUTE_NAMES from '@/store/consts/route-names'
const PatientFormComponent = defineAsyncComponent(() => import('@/components/PatientForms/' + config.patientFormComponentName))

export default {
  name: 'PatientForm',
  components: {
    PatientFormComponent
  },
  setup(_, context) {
    let loading = ref(true)
    const confirmAppointmentUrl = computed(() => bookingStore.getState().apiUrls.confirmAppointmentUrl)
    const sendConfirmationSMSUrl = computed(() => bookingStore.getState().apiUrls.sendConfirmationSMS)
    const patientFormSubmitted = computed(() => bookingStore.getState().patientForm.submitted)
    const patientFormData = computed(() => bookingStore.getState().patientForm.data)
    let selectedBodyPartId = computed(() => bookingStore.getState().bodyPartId)
    let selectedInsurer = computed(() => bookingStore.getState().insurer)
    let selectedClinicId = computed(() => bookingStore.getState().clinicId)
    let selectedRegionId = computed(() => bookingStore.getState().scanRegionId)
    const verificationCode = ref(null)
    const verificationCodeForm = ref()
    const verificationCodeFormSubmitted = ref(false)
    const verificationCodeError = ref('')
    const confirmationError = ref('')
    const verificationCodeRules = [
      v => !!v || Vue.prototype.$_t('SMS code is required'),
      v => !isNaN(v) || Vue.prototype.$_t('SMS code must be numeric'),
      v => (v && v.length === 6) || Vue.prototype.$_t('SMS code must be 6 digits long'),
    ]
    const confirmationCountdownDate = ref(new Date())
    const currentDateInMs = ref(Math.trunc((new Date()).getTime() / 1000))
    const confirmationCountdownDateInMs = computed(() => Math.trunc(Date.parse(confirmationCountdownDate.value) / 1000))
    const confirmationCountdownSeconds = computed(() => {
      return (confirmationCountdownDateInMs.value - currentDateInMs.value) % 60;
    })
    const confirmationCountdownMinutes = computed(() => {
      return Math.trunc((confirmationCountdownDateInMs.value - currentDateInMs.value) / 60) % 60;
    })

    const resendCountdownDate = ref(new Date())
    const resendCountdownDateInMs = computed(() => Math.trunc(Date.parse(resendCountdownDate.value) / 1000))
    const resendCountdownSeconds = computed(() => {
      return (resendCountdownDateInMs.value - currentDateInMs.value) % 60;
    })
    const resendCountdownMinutes = computed(() => {
      return Math.trunc((resendCountdownDateInMs.value - currentDateInMs.value) / 60) % 60;
    })

    const canResend = ref(false)

    const init = () => {
      bookingStore.setShowStepper(true)
      bookingStore.setStepName('patient-form')
      loading.value = false
    }

    onMounted(init)

    const startCountdown = () => {
      let now = new Date()
      confirmationCountdownDate.value = new Date(now.getTime() + config.confirmationCountdownInSeconds * 1000)
      resendCountdownDate.value = new Date(now.getTime() + 60 * 1000) //1min
      currentDateInMs.value = Math.trunc((new Date()).getTime() / 1000);

      window.setInterval(() => {
        currentDateInMs.value = Math.trunc((new Date()).getTime() / 1000);
        if (confirmationCountdownMinutes.value === 0 && confirmationCountdownSeconds.value === 0) {
          window.location.reload()
        }
        if (resendCountdownMinutes.value === 0 && resendCountdownSeconds.value === 0) {
          canResend.value = true
        }
      },1000);
    }

    const sendConfirmationSMS = () => {
      canResend.value = false
      startCountdown()
      rest.get(sendConfirmationSMSUrl.value).then(response => {
        if (response.status !== 'S') {
          Vue._notify.error('Could not send confirmation SMS');
          return
        } else {
          if (response.response.sms_sent === false) {
            verificationCodeError.value = Vue.prototype.$_t('Could not send confirmation SMS')
          }
        }
      })
    }

    const verifySMSCode = () => {
      if (verificationCodeForm.value.validate()) {
        verificationCodeError.value = ''
        verificationCodeFormSubmitted.value = true
        rest.get(confirmAppointmentUrl.value, {
          bodyPartId: selectedBodyPartId.value,
          regionId: selectedRegionId.value,
          insurerId: selectedInsurer.value.insurer_id,
          clinicId: selectedClinicId.value,
          authCode: verificationCode.value
        }).then(response => {
          verificationCodeFormSubmitted.value = false
          if (response.status !== 'S' && response.error_descr && response.error_descr.status === 'FAIL') {
            confirmationError.value = response.error_descr.errorMessage || Vue.prototype.$_t('Could not confirm appointment, try again.');
          }
          else if (response.status !== 'S') {
            verificationCodeError.value = Vue.prototype.$_t('Incorrect SMS code, try again.');
          }
          else {
            context.root.$router.push(ROUTE_NAMES.confirmation.path);
          }
        })
      }
    }

    const resendSMSCode = () => {
      sendConfirmationSMS()
    }

    const twoDigits = (value) => {
      if (value < 0) {
        return '00';
      }
      if (value.toString().length <= 1) {
        return `0${value}`;
      }
      return value;
    }

    watch(patientFormSubmitted, (val) => {
      if (val) {
        sendConfirmationSMS()
      }
    })

    return {
      loading,
      config,
      patientFormSubmitted,
      patientFormData,
      verificationCode,
      verificationCodeRules,
      verifySMSCode,
      verificationCodeForm,
      verificationCodeFormSubmitted,
      verificationCodeError,
      confirmationError,
      confirmationCountdownSeconds,
      confirmationCountdownMinutes,
      twoDigits,
      resendSMSCode,
      canResend,
      resendCountdownSeconds,
      resendCountdownMinutes
    }
  }
}
</script>
