<template>
  <div id="app">
    <v-app>
      <router-view />
      <notifications group="booking" />
    </v-app>
  </div>
</template>

<script>
  export default {
    setup() {
    }
  }
</script>
