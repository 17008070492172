<template>
  <div>
    <v-dialog
      v-model="whereToFindEReferralDialog"
      transition="dialog-top-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar color="primary" dark>{{
          $_t('Where can I find the E-referral PIN?')
        }}</v-toolbar>
        <v-card-text>
          <img
            src="@/assets/images/e-referral.png"
            alt=""
            class="full-width cursor-pointer"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="whereToFindEReferralDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="whereToFindEReferralProceduresDialog"
      transition="dialog-top-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar color="primary" dark>{{
          $_t('Where can I find the missing data?')
        }}</v-toolbar>
        <v-card-text>
          <img
            src="@/assets/images/e-referral-procedures.png"
            alt=""
            class="full-width cursor-pointer"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="whereToFindEReferralProceduresDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p class="mb-8">
      <span class="title grey--text text--darken-1">{{
        $_t('Provide the e-referral details')
      }}</span>
      <a
        @click="whereToFindEReferralDialog = true"
        class="d-block d-md-inline ml-md-2"
        >{{ $_t('Where can I find the E-referral PIN?') }}</a
      >
    </p>
    <v-form ref="referralFormEl" v-model="referralFormValid" class="mb-10">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="referralForm.patientNumber"
            :label="$_t('PESEL')"
            :rules="[validationRules.patientNumber]"
            :disabled="referralSearching"
            hide-details="auto"
            type="text"
            color="primary"
            class="mb-2"
            required
            outlined
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="referralForm.pin"
            :label="$_t('E-referral PIN')"
            :rules="[validationRules.pin]"
            :disabled="referralSearching"
            hide-details="auto"
            type="password"
            color="primary"
            class="mb-2"
            required
            outlined
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="mb-4">
            {{
              $_t('E referral only for scheduling best slot.')
            }}
            <br>
            {{
              $_t('E referral will not be submitted.')
            }}
          </p>
          <v-checkbox
            v-model="referralForm.policyAgreed"
            :rules="[validationRules.policy]"
            :disabled="referralSearching"
            hide-details="auto"
            class="mt-0 mb-8"
            required
          >
            <template v-slot:label>
              <div>
                *
                {{
                  $_t('I agree to process my data in agreement with Affidea')
                }}
                    <a
                      @click.stop
                      :href="config.policyLink"
                      target="_blank"
                    >
                      {{ $_t('Privacy Policy') }}
                    </a>
              </div>
            </template>
          </v-checkbox>
          <div class="text-center">
            <vue-recaptcha
              ref="recaptchaEl"
              @verify="onRecaptchaVerify"
              @error="onRecaptchaError"
              @expired="onRecaptchaExpired"
              :sitekey="config.recaptchaKey"
              :loadRecaptchaScript="true"
              size="invisible"
            >
            </vue-recaptcha>
            <v-btn
              :loading="referralSearching"
              @click="onRetrieveReferralClicked"
              color="primary"
              large
              class="full-width"
              type="submit"
              outlined
              >{{ $_t('Retrieve e-referral') }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-card v-if="showReferralNotFound" ref="referralNotFoundEl" class="mb-12" outlined>
      <v-card-title>
        {{ $_t('e-Referral not found header') }}
      </v-card-title>
      <v-card-text>
        <p class="body-1">{{ $_t('e-Referral not found part 1.') }}</p>
        <p class="body-1">{{ $_t('e-Referral not found part 2.') }}</p>
      </v-card-text>
    </v-card>

    <ReferralPreview
      v-if="referralFound"
      v-bind:_referral="referral"
      v-bind:_procedure="procedure"
      class="mb-8"
    ></ReferralPreview>

    <div v-if="referralFound && !procedureMapped" class="mb-8">
      <span class="d-block mb-2 grey--text text--darken-1">{{
        $_t(
          "The procedure wasn't specified at e-referral. Please provide with the procedure details"
        )
      }}</span>
      <a
        @click="whereToFindEReferralProceduresDialog = true"
        class="d-block mb-2 primary--text text--darken-1"
        >{{ $_t('Where can I find the missing data?') }}</a
      >
    </div>

    <Procedure
      v-if="referralFound && !procedureMapped"
      v-bind:proceedToInsurers="false"
      class="mb-8"
    ></Procedure>

    <div class="text-right">
      <v-btn
        v-if="referralFound && procedureMapped"
        ref="bookAppointmentButtonEl"
        :loading="bookingAppointment"
        @click="onBookAppointmentClicked"
        color="primary"
        class="mb-8"
        large
        >{{ $_t('Book appointment') }}</v-btn
      >
    </div>

    <v-dialog
        v-model="bookingAppointment"
        persistent
        width="200"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text class="py-8">
          <p class="mb-4 text-center">{{ $_t('Booking appointment') }}</p>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ROUTE_NAMES from '@/store/consts/route-names';
import REFERRAL_STEPS from '@/store/consts/referral-steps';
import config from '@/config';
import Vue from 'vue';
import VueRecaptcha from 'vue-recaptcha';
import {
  ref,
  reactive,
  onMounted,
  computed,
  watch
} from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import rest from '@/plugins/rest';
import { validatePatientNumber } from '@/plugins/validations.js';
import ReferralPreview from '@/components/Referral/Preview';
import Procedure from '@/components/Procedure';

export default {
  components: {
    VueRecaptcha,
    ReferralPreview,
    Procedure
  },
  name: 'ReferralSearch',
  setup(_, context) {
    const init = async () => {
      bookingStore.setShowStepper(true);
      bookingStore.setStepName('procedure');
      bookingStore.setReferralStep(REFERRAL_STEPS.iHaveTheReferral);
    };

    onMounted(init);

    const whereToFindEReferralDialog = ref(null);
    const whereToFindEReferralProceduresDialog = ref(null);
    const recaptchaEl = ref(null);
    const referralFormEl = ref(null);
    const referralFoundEl = ref(null);
    const referralNotFoundEl = ref(null);
    const bookAppointmentButtonEl = ref(null);
    const referralFormValid = ref(false);
    const referralSearching = ref(false);
    const scansSearching = ref(false);
    const bookingAppointment = ref(false);
    const referralForm = reactive({
      patientNumber: null,
      pin: null,
      policyAgreed: false
    });
    let referral = ref(null);
    let referralFound = ref(false);
    let procedure = ref(null);
    let procedureMapped = ref(false);
    let showReferralNotFound = ref(false);
    const selectedScanId = computed(() => bookingStore.getState().scanId);
    const scanAndBodyPartSelected = computed(
      () => bookingStore.getState().scanId && bookingStore.getState().bodyPartId
    );
    const selectedInsurerId = computed(() => bookingStore.getState().insurerId);
    const validationRules = {
      policy: (value) =>
        !!value ||
        Vue.prototype.$_t('Agreement must be accepted in order to continue'),
      patientNumber: (value) =>
        validatePatientNumber(value) ||
        Vue.prototype.$_t('Please enter correct PESEL.'),
      pin: (value) => !!value || Vue.prototype.$_t('Please enter correct PIN.')
    };

    watch(scanAndBodyPartSelected, () => {
      if (!procedureMapped.value && scanAndBodyPartSelected.value) {
        handleBookingAppointment();
      }
    });

    const goToRoute = (routeName) => {
      context.root.$router.push(routeName).catch(() => {});
    };
    const onRecaptchaVerify = (token) => {
      return new Promise(function(resolve) {
        handleReferral(token);
        resolve();
      });
    };
    const onRecaptchaError = () => {
      return new Promise(function(resolve) {
        Vue._notify.error(Vue.prototype.$_t('Recaptcha error. Try again.'));
        resolve();
      });
    };
    const onRecaptchaExpired = () => {
      return new Promise(function(resolve) {
        Vue._notify.error(Vue.prototype.$_t('Recaptcha expired. Try again.'));
        resolve();
      });
    };
    const onRetrieveReferralClicked = (e) => {
      showReferralNotFound.value = false;
      referral.value = null;
      referralFound.value = null;
      referralFormEl.value.validate();
      if (referralFormValid.value) {
        if (!bookingStore.getState().isUserWhitelisted) {
          recaptchaEl.value.execute();
        } else {
          handleReferral(1);
        }
      }
      e.preventDefault();
    };
    const onBookAppointmentClicked = () => {
      handleBookingAppointment();
    };
    const handleReferral = async (token) => {
      try {
        referralSearching.value = true;
        procedure.value = null;
        procedureMapped.value = false;
        bookingStore.setScanId(null);
        bookingStore.setBodyPartId(null);
        const referralResponse = await getReferral(token);
        if (!referralResponse) {
          bookingStore.setReferral(null);
          showReferralNotFound.value = true;
          return;
        } else {
          if (referralResponse.icd9Codes.length) {
            referralSearching.value = true;
            const procedureResponse = await getProcedureByICD9(
              referralResponse.icd9Codes
            );
            if (procedureResponse) {
              procedure.value = procedureResponse;
              procedureMapped.value = true;
              bookingStore.setScanId(procedure.value.scan_id);
              bookingStore.setBodyPartId(procedure.value.body_part_id);
            }
          }
          bookingStore.setReferral(referral);
          referral.value = referralResponse;
          referralFound.value = true;
        }
      } catch {
        Vue._notify.error(
          Vue.prototype.$_t('Could not retrieve e-referral. Please try again.')
        );
      } finally {
        referralSearching.value = false;
        scansSearching.value = false;
        recaptchaEl.value.reset();
      }
    };
    const handleBookingAppointment = async () => {
      try {
        bookingAppointment.value = true;
        const appointmentResponse = await bookAppointment();
        if (!appointmentResponse.appointmentAccessCode) {
          Vue._notify.error(
            Vue.prototype.$_t('Could not book appointment. Please try again.')
          );
        } else {
          bookingStore.setAppointmentAccessCode(
            appointmentResponse.appointmentAccessCode
          );
          bookingStore.setReferralProcedure(
            appointmentResponse.procedure
              ? appointmentResponse.procedure
              : null
          );
          try {
            //reinitialize all data with appointment access token
            const status = await bookingStore.getApiUrls(
              appointmentResponse.appointmentAccessCode,
              bookingStore.getState().scanId
            );
            if (status !== 'S') {
              throw 'Invalid request response status';
            }
          } catch {
            Vue._notify.error('Failed to initialize booking session');
          }
          goToBooking();
        }
      } catch {
        Vue._notify.error(
          Vue.prototype.$_t('Could not book appointment. Please try again.')
        );
      } finally {
        bookingAppointment.value = false;
      }
    };
    const getReferral = async (token) => {
      const getEReferralUrl = bookingStore.getState().apiUrls.getEReferralUrl;
      const response = await rest.get(getEReferralUrl, {
        patientNumber: referralForm.patientNumber,
        pin: referralForm.pin,
        token: token
      });
      if (response.status === 'S' && response.response.status === 'SUCCESS') {
        return response.response.referral;
      } else {
        return null;
      }
    };
    const getProcedureByICD9 = async (icd9Codes) => {
      const getProcedureIdByICD9Url = bookingStore.getState().apiUrls
        .getProcedureIdByICD9Url;
      const response = await rest.post(getProcedureIdByICD9Url, {
        icd9Codes: icd9Codes
      });

      if (response.status === 'S' && response.response.proceduresFound) {
        return response.response.procedure;
      } else {
        return null;
      }
    };
    const bookAppointment = async () => {
      const bookAppointmentUrl = bookingStore.getState().apiUrls
        .bookAppointmentUrl;
      const response = await rest.post(bookAppointmentUrl, {
        scanId: selectedScanId.value,
        insurerId: selectedInsurerId.value,
        patientNumber: referralForm.patientNumber,
        referralKey: referral.value.referralKey,
        referralId: referral.value.referralId,
        referralIdRoot: referral.value.referralIdRoot
      });
      if (response.status === 'S') {
        return response.response;
      } else {
        return null;
      }
    };
    const goToBooking = () => {
      context.root.$router.push(ROUTE_NAMES.insurers).catch(() => {});
    };

    return {
      ROUTE_NAMES,
      config,
      whereToFindEReferralDialog,
      whereToFindEReferralProceduresDialog,
      recaptchaEl,
      referralFormEl,
      referralFoundEl,
      referralNotFoundEl,
      bookAppointmentButtonEl,
      referralFormValid,
      referralSearching,
      bookingAppointment,
      referralForm,
      referral,
      referralFound,
      procedure,
      procedureMapped,
      scanAndBodyPartSelected,
      showReferralNotFound,
      validationRules,
      goToRoute,
      onRetrieveReferralClicked,
      onBookAppointmentClicked,
      onRecaptchaVerify,
      onRecaptchaError,
      onRecaptchaExpired
    };
  }
};
</script>
