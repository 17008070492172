<template>
  <v-card>
    <v-card-title class="primary--text text-body-1 font-weight-bold">
      <span class="cursor-pointer" @click="setInsurer(insurer)">{{insurer.insurer_name_web}}</span>
    </v-card-title>
    <v-card-text v-if="insurer.insurer_note_web" v-html="insurer.insurer_note_web"></v-card-text>
    <div class="divider-line"></div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="setInsurer(insurer)" class="text-capitalize">
        {{$_t('Choose')}}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { bookingStore } from '@/store/booking-store'
import ROUTE_NAMES from '@/store/consts/route-names';

export default {
  name: 'InsurerCard',
  props: {
    insurer: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const setInsurer = (insurer) => {
      bookingStore.setInsurer(insurer)
      context.root.$router.push(ROUTE_NAMES.clinics.path)
    }
    return {
      setInsurer
    }
  }
}
</script>
