<template>
  <div>
    <v-card
        elevation="0"
        class="background-gradient white--text"
        style="position: absolute; width: 100%; height: 200px; padding-top: 30px;"
        v-if="!showConfirmation"
    >
      <v-card-title class="mx-md-12 headline font-weight-bold">{{
          getStep ? $_t(getStep.title) : ''
        }}
      </v-card-title>
      <v-card-subtitle class="white--text mx-md-12">{{
          getStep ? $_t(getStep.subTitle) : ''
        }}
      </v-card-subtitle>
    </v-card>
    <v-card
        ref="appEl"
        :class=" (!showConfirmation) ? 'mx-md-16 px-md-16' : ''"
        :style="{ position: 'relative', 'margin-top': (!showConfirmation) ? '120px' : '0' }"
    >
      <div v-if="loading" class="text-center pt-12 pb-12">
        <v-progress-circular
            :size="75"
            color="primary"
            indeterminate
            class="mb-12"
        ></v-progress-circular>
        <br/>
        <span class="subtitle-1">{{ $_t('Initializing...') }}</span>
      </div>
      <div v-else-if="!loading && !showConfirmation">
        <v-container class="mb-8 px-4 py-8">
          <back-button v-bind:_showLabel="true" v-if="getStep.showStepper" class="mb-8"></back-button>
          <BookingSteps v-if="getStep.showStepper" class="mt-n8 mb-6"></BookingSteps>
          <Referral v-if="showReferrals"></Referral>
          <Procedure v-if="showProcedure" v-bind:proceedToInsurers="true"></Procedure>
          <Insurer v-if="showInsurer"></Insurer>
          <Clinic v-if="showClinic"></Clinic>
          <PatientForm v-if="showPatientForm"></PatientForm>
          <Callback v-if="showCallback"></Callback>
        </v-container>
      </div>
      <Confirmation v-if="showConfirmation"></Confirmation>
      <div v-if="config.languageSwitchEnabled" class="pa-5">
        <span class="subtitle-2 primary--text"
        >{{ $_t('Change language') }}:</span
        >
        <v-btn text small color="primary" @click="switchLanguage('pl')"
        >PL
        </v-btn
        >
        <v-btn text small color="primary" @click="switchLanguage('en')"
        >EN
        </v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import config from '@/config';
import ROUTE_NAMES from '@/store/consts/route-names';
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  watch
} from '@vue/composition-api';
import {bookingStore} from '@/store/booking-store';
import Vue from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import BackButton from '@/components/Shared/BackButton';
import BookingSteps from '@/components/BookingSteps';
import Referral from '@/components/Referral/Referral';
import Procedure from '@/components/Procedure';
import Insurer from '@/components/Insurer/Insurer';
import Clinic from '@/components/Clinic/Clinic';
import PatientForm from '@/components/PatientForm';
import Callback from '@/components/Callback/Callback';
import Confirmation from '@/components/Confirmation'
import rest from "@/plugins/rest";

export default {
  name: 'Booking',
  components: {
    Confirmation,
    BackButton,
    BookingSteps,
    Referral,
    Procedure,
    Clinic,
    Insurer,
    PatientForm,
    Callback
  },
  setup(_, context) {
    const appEl = ref(null);
    let loading = ref(true);
    let observer = null;

    onMounted(async () => {
      if (context.root.$route.path !== config.startPage && context.root.$route.path !== ROUTE_NAMES.callback.path) {
        context.root.$router.push({path: config.startPage}).catch(() => {
        })
      }

      if (!config.languageSwitchEnabled) {
        localStorage.setItem('lang', config.defaultLanguage);
      }

      setPageTitles(ROUTE_NAMES.eReferrals.path);
      try {
        bookingStore.getApiUrls().then((status) => {
          if (status !== 'S') {
            throw 'Invalid request response status';
          }
          try {
            rest.get(
                bookingStore.getState().apiUrls.getIsUserWhitelistedUrl,
            ).then((resp) => {
              if (resp.status === 'S') {
                bookingStore.setIsUserWhitelisted(resp.response.whitelisted)
              }
              loading.value = false;
            })
          } catch {
            bookingStore.setIsUserWhitelisted(false)
            loading.value = false;
          }
        });
      } catch {
        Vue._notify.error('Failed to initialize booking session');
        return
      }

      if (window.parent) {
        initWatchHeightObserver();
      }
    });
    onBeforeUnmount(() => {
      if (window.parent) {
        killWatchHeightObserver();
      }
    });
    watch(
        () => context.root.$route,
        (r) => {
          setPageTitles(r.path);
          window.parent.postMessage(
              {
                name: 'routeChanged',
                path: r.path
              }, '*');
        }
    );
    const initWatchHeightObserver = () => {
      observer = new ResizeObserver(onHeightChanged);
      observer.observe(appEl.value.$el);
    };
    const killWatchHeightObserver = () => {
      observer.unobserve(appEl.value.$el);
    };
    const onHeightChanged = () => {
      const height = appEl.value.$el.offsetHeight;
      window.parent.postMessage({name: 'heightChanged', height: height}, '*');
    };
    const showReferrals = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.eReferrals.path)
    );
    const showProcedure = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.procedures.path)
    );
    const showInsurer = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.insurers.path)
    );
    const showClinic = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.clinics.path)
    );
    const showPatientForm = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.patientForm.path)
    );
    const showCallback = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.callback.path)
    );
    const showConfirmation = computed(() =>
        context.root.$route.path.startsWith(ROUTE_NAMES.confirmation.path)
    );
    const getStep = computed(() => bookingStore.getState().step);

    const switchLanguage = (langCode) => {
      if (config.languageSwitchEnabled) {
        localStorage.setItem('lang', langCode);
        window.location.reload();
      }
    };
    const setPageTitles = (path) => {
      const route = Object.values(ROUTE_NAMES).filter((r) =>
          r.path.startsWith(path)
      );
      if (route.length) {
        bookingStore.setStepTitle(Vue.prototype.$_t(route[0].title));
        bookingStore.setStepSubTitle(Vue.prototype.$_t(route[0].subTitle));
      }
    };

    return {
      config,
      appEl,
      loading,
      showReferrals,
      showProcedure,
      showInsurer,
      showClinic,
      showPatientForm,
      showCallback,
      showConfirmation,
      getStep,
      switchLanguage
    };
  }
};
</script>

<style>
.divider-line {
  height: 1px;
  background: #e7e7e7;
  width: 95%;
  margin: 0 auto;
}

.primary--text {
  color: var(--primary-color);
}

.background-gradient {
  background: radial-gradient(
      circle at 0,
      #31d4ff 25%,
      #3295ff 100%,
      #808080 100%
  );
  opacity: 0.81;
}

.title-2 {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: .0125em;
  line-height: 2rem;
  font-family: Roboto, sans-serif;
}
</style>
