export default {
  displayReferralStep: (process.env.VUE_APP_DISPLAY_REFERRAL_STEP === 'true'),
  contactPhoneNumber: (process.env.VUE_APP_CONTACT_PHONE_NUMBER) ?? '+ 22 44 11 111',
  policyLink: (process.env.VUE_APP_POLICY_LINK) ?? 'https://google.com',
  cashPayerInsurerId: (process.env.VUE_APP_CASH_PAYER_INSURER_ID) ?? null,
  recaptchaKey: '6LfQNT4aAAAAAJnjPd3p-_ZcYYPtEbs0j23ThTbu',
  languageSwitchEnabled: (process.env.VUE_APP_LANGUAGE_SWITCH_ENABLED && process.env.VUE_APP_LANGUAGE_SWITCH_ENABLED === 'true'),
  patientFormComponentName: (process.env.VUE_APP_PATIENT_FORM_COMPONENT_NAME) ?? 'AffideaPoland',
  currency: (process.env.VUE_APP_CURRENCY) ?? 'zł',
  homepageLink: (process.env.VUE_APP_HOMEPAGE_LINK) ?? 'https://affidea.pl',
  startPage: (process.env.VUE_APP_START_PAGE) ?? '/e-referrals/start',
  contactHours: (process.env.VUE_APP_CONTACT_HOURS) ?? 'Pn - Pt&nbsp;&nbsp;7:00 - 20:00',
  scanLearnMoreLinks: {
    MRI: (process.env.VUE_APP_LEARN_MORE_LINK_MRI) ?? 'https://affidea.pl/wp-content/uploads/2021/01/affidea_przygotowanie_do_badania_mr.pdf',
    CT: (process.env.VUE_APP_LEARN_MORE_LINK_CT) ?? 'https://affidea.pl/wp-content/uploads/2021/01/affidea_przygotowanie_do_badania_tk.pdf'
  },
  dateFormat: (process.env.VUE_APP_DATE_FORMAT) ?? 'DD.MM.YYYY',
  defaultLanguage: (process.env.VUE_APP_DEFAULT_LOCALE) ?? 'pl',
  alternativeConfirmation:(process.env.VUE_APP_ALTERNATIVE_CONFIRMATION === 'true'),
  confirmationCountdownInSeconds: (process.env.VUE_APP_CONFIRMATION_COUNTDOWN_IN_SECONDS) ?? '600',
  excludeCurrentDayFromCallback: (process.env.VUE_APP_EXCLUDE_CURRENT_DAY_CALLBACK === 'true')
}

