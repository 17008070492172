<template>
    <v-dialog v-model="dialog" width="95vw" persistent>
        <v-card>
            <v-toolbar color="primary" dense>
                <v-spacer />
                <v-btn icon class="white--text" @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <iframe 
                v-if="iframeSrc" 
                class="pt-0" 
                :src="iframeSrc" 
                width="100%"
                loading="lazy" 
                @load="onLoad()"
                @error="onError()" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
            <v-progress-linear v-if="loading" indeterminate color="primary" />
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    props: {
        dialog: Boolean,
        iframeSrc: String
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        onLoad() {
            this.loading = false
        },
        onError() {
            this.loading = false
        }
    },
    mounted() {
        this.loading = true
    }
}
</script>

<style scoped>
iframe {
    height: 95vh;
}
</style>
